// Swiper JS Plugin
// [ https://uiinitiative.com/catalog/slicer-slider ]
import EffectSlicer from '/src/build/lib/swiperjs/effect-slicer.esm.js';
window.EffectSlicer = EffectSlicer;
import '/src/build/lib/swiperjs/effect-slicer.css';

// [ https://uiinitiative.com/catalog/shaders-slider ]
import SwiperGL from '/src/build/lib/swiperjs/swiper-gl.esm.js';
window.SwiperGL = SwiperGL;
import '/src/build/lib/swiperjs/swiper-gl.css';

// [ https://uiinitiative.com/catalog/shutters-slider ]
import EffectShutters from '/src/build/lib/swiperjs/effect-shutters.esm.js';
window.EffectShutters = EffectShutters;
import '/src/build/lib/swiperjs/effect-shutters.css';

// [ https://uiinitiative.com/catalog/carousel-slider ]
import EffectCarousel from '/src/build/lib/swiperjs/effect-carousel.esm.js';
window.EffectCarousel = EffectCarousel;
import '/src/build/lib/swiperjs/effect-carousel.css';